import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import classes from './style.module.scss'
import { IMAGE_GEEKO_LTD } from '../../../../../constants/index'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { unitPrice, getLowerPrice, getDelPrice, isPromotion } from '../../../../../utils/product'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Iconfont } from "../../../../components/icon/iconfont";
import CartIcon from '../cart/cart-icon'
import { getProductUrl } from '../../../../../utils/product'
import useStyles from 'isomorphic-style-loader/useStyles'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

import withItemScroll from '../../../../hocs/item-scroll'
import { useApp, useLanguage, useWebp } from '../../../../hooks/common'
import { FormattedMessage, useIntl } from 'react-intl'
import { getSensorsUrl } from '../../../../utils/sensor'
import { BlackMask } from '../../../../components/mask'
// import ReactPlayer from 'react-player/lazy'
import CountDown from '../../../../components/count-down'
// import withEnterView from '../../../../hocs/enter-viewport'
import NormalConfigConfirm from '../../../../components/alert/config-confirm'
import { removeWishListProduct } from '../../../../../api'
import { setWishList } from '../../../../../store/actions/global'
import { action_fetch_wishlist_count, initWishList, setCustomerLoading } from '../../../../../store/actions/pages/customer'
import { useSelector } from 'react-redux'
import Alert from '../../../../components/alert'
import { useDispatch } from 'react-redux'
import Stars from '../../../details/module/product-star-score'



// const ListingVideo = withEnterView(props => {

// 	const { link, poster, innerRef, entered } = props
// 	const [duration, setDuration] = useState(0)
// 	const [lefting, setLefting] = useState(0)
// 	const [ready, setReady] = useState(false)
// 	const [ended, setEnded] = useState(false)
// 	const ref = useRef()

// 	const durationHandle = d => {
// 		setDuration(d)
// 	}

// 	const endedHandle = () => {
// 		ref.current?.getInternalPlayer()?.playVideo?.()
// 		setLefting(lefting+1)
// 		// setEnded(true)
// 	}

// 	const clickHandle = e => {
// 		// e.preventDefault()
// 		// e.stopPropagation()
// 		// setEnded(false)
// 	}

// 	return <div className={classes.VideoContainer} ref={innerRef}>

// 		{
// 			entered && !ended ? <React.Fragment>
// 				<div className={classes.Timmer}>
// 					{
// 						duration && ready && <CountDown key={lefting} offset={duration * 1000} />
// 					}
// 				</div>

// 				<ReactPlayer className={classes.ReactPlayer}
// 					ref={ref}
// 					width='100%'
// 					height='100%'
// 					url={`https://www.youtube.com/watch?v=${link}`}
// 					onDuration={durationHandle}
// 					muted
// 					playing={ready}
// 					onReady={() => { setReady(true) }}
// 					onEnded={endedHandle}
// 					config={{
// 						youtube: {
// 							playerVars: { showinfo: 0, controls: 0, modestbranding: 1, playsinline: 1, autohide: 1, rel: 0, iv_load_policy:3 },
// 							embedOptions: { showinfo: 0, controls: 0, modestbranding: 1, playsinline: 1, autohide: 1, rel: 0, iv_load_policy:3 }
// 						}
// 					}} />


// 				{
// 					!ready && <LazyLoadImage src={poster} />
// 				}

// 			</React.Fragment> : <LazyLoadImage src={poster} />
// 		}



// 	</div>
// })


const convertCommaToBrTitle = title => title?.replace(/,/g, '<br/>')


const ColorSwiper = props => {
	useStyles(classes)
	const { colors, selectedColor, onColor, isSmall } = props
	const [spaceBetween, setSpaceBetween] = useState(0)

	const clickHandle = useCallback(color => {
		onColor(color)
	})

	useEffect(() => {
		const rem = 2 * 14 / 75
		const [topSize] = window.document.documentElement.style.fontSize.split('px')
		setSpaceBetween(topSize * rem)
	}, [])

	const preventClickHandle = e => {
		e.preventDefault()
		e.stopPropagation()
	}


	return <div className={classes.ColorSwiper} onClick={preventClickHandle}>
		<div>
			<Swiper
				onSlideChange={() => { }}
				onSwiper={(swiper) => { }}
				spaceBetween={14}
				slidesPerView={isSmall ? 7.5 : 5}
			>
				{
					colors?.map((color, index) => <SwiperSlide className={classes.ColorSlide} key={index}>
						<span className={`${classes.ColorImage} ${color.productId === selectedColor?.productId ? classes.Active : ''}`} onClick={e => { clickHandle(color) }} >
							<LazyLoadImage src={color.colorImage} alt={color.color} />
						</span>
					</SwiperSlide>)
				}
			</Swiper>
		</div>

	</div>
}

export const EmptyItem = props => {
	return <figure className={classes.NormalItem}>
		<div>
			<div className={classes.Image}>
				<img alt="empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
			</div>
		</div>
		<figcaption>
			<div className={classes.Prices}>
				<div>

				</div>

				<div>
					<Iconfont>&#xe6a8;</Iconfont>
				</div>
			</div>
		</figcaption>
	</figure>
}


export default withRouter(withItemScroll(props => {

	useStyles(classes)

	const { location } = props

	const query = qs.parse(location?.search)

	const { product, position, column, similar, sensors, giftInfo, product_type, filter, isWishList, wishListSelectEvent, wishListSelect, isDrawGift, listType, noAddWish, festival } = props

	const isGift = isDrawGift || !!giftInfo

	const language = useLanguage()


	const { differentColorStyleVariants, promotionalSign } = product
	const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))
	const [showWishlistOption, setShowWishListOption] = useState(false);
	const [selectWish, setSelectWish] = useState(false);
	const pageCustomer = useSelector(state => state.pageCustomer);
	const { wishListData, wishListFilterResult } = pageCustomer;
	const { wishlist } = useSelector(state => state.global);
	const dispatch = useDispatch();
	const Intl = useIntl();

	const isApp = useApp()
	const isWebp = useWebp() && product?.status === '1'

	const [lowerPrice, delPrice, isProm] = [
		getLowerPrice(product),
		getDelPrice(product),
		isPromotion(product)
	]


	let off = 0
	if (delPrice) {
		off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
	}

	// const url = similar ? `/similar/${selectedColor?.id || product?.id}.html` : getProductUrl( selectedColor?.id || product?.id, product?.name, selectedColor?.color, query?.promotionType? `promotionType=${query?.promotionType}`:'' )

	let suffix = ''

	if (query?.promotionType) {
		suffix += `promotionType=${query?.promotionType}`
	}

	if (product_type) {
		suffix += `${suffix ? '&' : ''}product_type=${product_type}`
	}

	const url = isApp ? `chic-me://chic.me/product?id=${selectedColor?.productId || product?.id}${isGift ? '&isGiftProduct=1' : ''}` : getProductUrl(selectedColor?.productId || product?.id, product?.name, selectedColor?.color, suffix, language)

	const promotionType = isProm ? product.promotion.type : ''

	const isActivePromotion = isProm && !product?.promotion?.countdownHidden

	const priceClass = isActivePromotion ? classes.FlashPrice : (off > 0 ? classes.PromotionPrice : classes.Price)

	const isClearancePromotion = promotionalSign?.type == "1" && promotionalSign?.bannerBackgroundImage && promotionalSign?.isClearance;

	// if(selectedColor){
	// 	selectedColor.appExclusiveZone={
	// 		enable: true,
	// 		message: '50% off on app'
	// 	}
	// }

	// if(product){
	// 	product.appExclusiveZone={
	// 		enable: true,
	// 		message: '50% off on app'
	// 	}
	// }

	const appExclusiveZone = selectedColor?.appExclusiveZone || product?.appExclusiveZone

	const handleProductClick = e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.resourcepage_title,
			resource_type: sensors?.resource_type,
			resource_content: sensors?.resource_content,
			resource_position: sensors?.resource_position,
			product_position: position + 1,
			filter
		})
	}

	const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.mainImage || product?.pcMainImage}`

	// const listingVideoLink = (!selectedColor || product?.id === selectedColor?.productId) ? product?.listingVideoLink : undefined

	// const listingVideoLink = (position === 6 || position === 7) ? 'JIThhAus2xY' : undefined
	// const listingVideoImage = product?.listingVideoImage ? `${IMAGE_GEEKO_LTD}${product?.listingVideoImage}` : imageUrl

	const confirmHandle = async (id, product) => {
		try {
			dispatch(setCustomerLoading(true));
			let response = await removeWishListProduct(id);
			if (response?.code === 200) {
				let newList = [...(wishListFilterResult || [])]?.filter(w => w?.id != id)
				let newWishList = [...(wishlist || [])]?.filter(w => w != id)
				dispatch(initWishList(newList))
				dispatch(setWishList(newWishList))
				dispatch(action_fetch_wishlist_count())
			} else {
				response?.result && Alert(response.result);
			}
			dispatch(setCustomerLoading(false));
		} catch (error) {
			console.log("error", error);
			dispatch(setCustomerLoading(false));
		}
	}

	const deleteHandle = (id, product) => {
		NormalConfigConfirm({
			message: {
				text: Intl.formatMessage({ id: "sure_delete_items", defaultMessage: "Are you sure you want to delete the item(s)?" }),
			},
			btn1: {
				clickHandle: () => {
					confirmHandle(id, product);
					// 单删确认点击
					if (window.GeekoSensors) {
						window.GeekoSensors.Track('WishlistButtonClick', {
							page_sort: "wish list",
							page_type: "收藏夹页",
							button_pv: "delete_confirm",
							is_success: true
						})
					}
				}
			},
			btn2: {
				clickHandle: () => {
					// 单删取消点击
					if (window.GeekoSensors) {
						window.GeekoSensors.Track('WishlistButtonClick', {
							page_sort: "wish list",
							page_type: "收藏夹页",
							button_pv: "cancel",
							is_success: true
						})
					}
				}
			}
		});

		// 单删点击
		if (window.GeekoSensors) {
			window.GeekoSensors.Track('WishlistButtonClick', {
				page_sort: "wish list",
				page_type: "收藏夹页",
				button_pv: "delete",
			})
		}
	}

	const preventClickHandle = e => {
		e.preventDefault()
		e.stopPropagation()
	}
	return <figure>
		<a href={url} ref={props.innerRef}
			product-id={product?.id}
			data-product-list-source
			data-promotion={promotionType}
			data-layer={product?.layerName}
			data-tracking={product.trackingRecords}
			data-product-source={product.dataSource}
			data-geeko-experiment={product.geekoExperimentId}
			data-geeko-id={product.geekoRequsestId}
			data-request-id={product.aliRequestId}
			data-experiment-id={product.aliExperimentId}
			data-column={column}
			data-product-position={position + 1}
			data-position={sensors?.resource_position}
			data-type={sensors?.resource_type}
			data-content={sensors?.resource_content}
			data-title={sensors?.resourcepage_title}
			data-filter={filter || sensors?.filter}
			data-product-type={product_type}
			target={isGift ? '_parent' : undefined}
			data-price={product?.usdPrice?.amount}
			data-parent-sku={product?.topFlag == "related" ? product?.parentSku : undefined}
			data-top-flag={product?.topFlag == "self" || product?.topFlag == "related" ? true : undefined}
			data-view-pattern={listType === '1' ? 'single' : 'double'}
			onClick={handleProductClick}
			className={`${listType === '1' ? classes.SpecilItem : classes.NormalItem} ${position === 0 ? classes.FirstImage : ''}`}
		>
			<div className={classes.ImageContainer}>
				<div className={`${classes.Image} ${isWishList && product?.status == '2' ? classes.GrayImage : ''}`}>

					{
						position === 0 ? <img src={imageUrl} alt={product?.name} /> : <LazyLoadImage src={imageUrl} alt={product?.name} />
					}

					{
						!!promotionalSign?.bannerBackgroundImage ? <React.Fragment>
							<div className={`${classes.PicPromotion} ${listType === '1' ? classes.Small : ''}`} style={{ backgroundImage: `url(${IMAGE_GEEKO_LTD}${promotionalSign.bannerBackgroundImage})` }}>
								{
									(promotionalSign?.endTime - promotionalSign?.serverTime > 1000) ? <span className={`${classes.TitleText}`}>
										<span className={classes.SText}>
											<FormattedMessage id="ends_in" defaultMessage="Ends in" />&nbsp;
										</span>

										<CountDown
											numberClass={classes.SText}
											labelClass={classes.SText}
											dotClass={classes.SText}
											offset={promotionalSign.endTime - promotionalSign.serverTime}
											showHour
										/>
									</span> : <span className={`${classes.TitleText} ${classes.SText}`}>{promotionalSign?.title}</span>
								}
							</div>
						</React.Fragment> : <React.Fragment>
							{
								product.combinatorialPromotion && <div className={`${classes.TitlePromotion} ${listType === '1' ? classes.Small : ''}`} style={{
									background: product.combinatorialPromotion.theme?.background,
									color: product.combinatorialPromotion.theme?.color,
								}}>
									<span className={classes.SText} dangerouslySetInnerHTML={{ __html: convertCommaToBrTitle(product?.combinatorialPromotion?.title) }} />
								</div>
							}
						</React.Fragment>
					}


					{
						product?.isPreOrder && <span className={classes.PreOrderFlag}>Pre order</span>
					}




					{
						product?.listingVideoLink && <span className={classes.PlayIcon} />
					}

					{
						isWishList && <React.Fragment>
							{
								wishListSelect ? <React.Fragment>
									<span
										className={`${classes.WishListSelect} ${selectWish ? classes.Active : ""}`}
										onClick={(evt) => {
											evt.stopPropagation();
											evt.preventDefault();
											setSelectWish(state => !state);
											wishListSelectEvent(product?.id);
										}}
									></span>
								</React.Fragment> : <React.Fragment>
									{
										!showWishlistOption ? <React.Fragment>
											<span className={classes.MeWishListMore} onClick={(evt) => {
												evt.stopPropagation();
												evt.preventDefault();
												setShowWishListOption(true);

												// 更多选择点击
												if (window.GeekoSensors) {
													window.GeekoSensors.Track('WishlistButtonClick', {
														page_sort: "wish list",
														page_type: "收藏夹页",
														button_pv: "more_choice",
													})
												}
											}}>
												<span>...</span>
											</span>
										</React.Fragment> : <React.Fragment>
											<BlackMask style={{ position: 'absolute', zIndex: 2 }} />
											<div className={classes.SuspendContainer}>
												<button onClick={(evt) => {
													evt.stopPropagation();
													evt.preventDefault();

													if (window.GeekoSensors) {
														window.GeekoSensors.Track('WishlistButtonClick', {
															page_sort: "wish list",
															page_type: "收藏夹页",
															button_pv: "findsimilar",
														})
													}

													window.location.href = `/similar/${product?.id}.html`;
												}}><FormattedMessage id="find_similar" defaultMessage="Find Similar" /></button>
												<button onClick={(evt) => {
													evt.stopPropagation();
													evt.preventDefault();
													deleteHandle(product?.id, product);
												}}><FormattedMessage id="delete" defaultMessage="Delete" /></button>
											</div>
										</React.Fragment>
									}
								</React.Fragment>
							}

						</React.Fragment>
					}


				</div>
				{
					product.flag ? (
						<span className={`${classes.OffTip} ${classes.PreFall}`}>{product.flag}</span>
					) : (

						// festival ? (off > 0 && <span className={classes.BlackFridayIcon}>{`-${off}%`}</span>) : 
						<React.Fragment>
							{
								product.isNew && <span className={classes.New}><FormattedMessage id="new" defaultMessage="New" /></span>
							}

							{
								!promotionalSign && <React.Fragment>
									{
										!isGift && isActivePromotion && !product.isNew && <span className={classes.FlashTip}>
											<span className={classes.Number}>-{off}%</span>
										</span>
									}


									{
										!isGift && !product.isNew && !isActivePromotion && off > 0 && <span className={classes.OffTip}>{`-${off}%`}</span>
									}
								</React.Fragment>
							}
						</React.Fragment>


					)
				}



			</div>


			<figcaption className={classes.InfoContainer}>
				<div>
					{
						listType === '1' && <div className={classes.Name}>
							{product?.name}
						</div>
					}
					<div className={classes.Prices}>
						<div>
							{product?.usedStartingPrice&&<span className={classes.fromPrice}><FormattedMessage id="from" defaultMessage={"From"} /> </span>}
							{
								isGift ? <span className={priceClass}>{product?.giftPrice ? unitPrice(product?.giftPrice) : unitPrice({ ...lowerPrice, amount: '0.00' })}</span> : <span className={priceClass}>{unitPrice(lowerPrice)}</span>
							}

							{' '}
							<span className={classes.PriceElement}>
								{
									delPrice && <React.Fragment>
										<del className={classes.Del}>{unitPrice(delPrice)}</del>

										{/* {product.isNew && <span className={classes.Off}>{`-${off}%`}</span>} */}

									</React.Fragment>
								}
							</span>
						</div>

						{
							product?.status != '2' && listType !== '1' && <div onClick={preventClickHandle} className={classes.CartIconEle}>

								<CartIcon sensors={
									{
										layerName: product?.layerName,
										promotionType,
										trackingRecords: product?.trackingRecords,
										dataSource: product?.dataSource,
										geekoExperimentId: product?.geekoExperimentId,
										geekoRequsestId: product?.geekoRequsestId,
										aliRequestId: product?.aliRequestId,
										aliExperimentId: product?.aliExperimentId,
										product_position: position + 1,
										price: product?.usdPrice?.amount,
										...sensors
									}
								} isGift={isGift} giftInfo={giftInfo} isWishList={isWishList} noAddWish={isWishList || noAddWish} product_type={product_type} productId={selectedColor ? selectedColor.productId : product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} />

							</div>
						}

					</div>

					{
						product?.estimatedPrice && !isGift &&
						<div className={classes.EstimatedPrice}>
							<Iconfont className={classes.EstimatedIcon}>&#xe7e9;</Iconfont>
							<FormattedMessage id="estimated" defaultMessage={"Estimated"} />
							<span className={classes.EPrice}>{unitPrice(product?.estimatedPrice)}</span>
						</div>
					}

					<div>
						{
							differentColorStyleVariants && differentColorStyleVariants.length > 1 && <ColorSwiper isSmall={listType === '1'} onColor={c => { setSelectedColor(c) }} selectedColor={selectedColor} colors={differentColorStyleVariants} />
						}
					</div>
					<div>
						{
							product?.isQuickShip && <span className={classes.QuickShipFlag}>
								<FormattedMessage id="quick_ship" defaultMessage="Quick Ship" />
							</span>
						}
						{
							!isGift && appExclusiveZone && appExclusiveZone.enable && <div className={`${classes.AppExclusive} ${listType === '1' ? classes.SmallText : ''}`}>
								<span dangerouslySetInnerHTML={{ __html: appExclusiveZone.message }} />
							</div>
						}

					</div>
				</div>
				{
					listType === '1' && <div onClick={preventClickHandle} className={classes.BottomTools}>
						{
							product.reviews > 0 ? <span className={classes.StarsBlack}>
								<Stars starScore={product.reviewAverageScore} />
								<span className={classes.Text}>({product.reviews})</span>
							</span> : <span />
						}
						<div>
							{
								product?.status != '2' && <div>

									<CartIcon sensors={
										{
											layerName: product?.layerName,
											promotionType,
											trackingRecords: product?.trackingRecords,
											dataSource: product?.dataSource,
											geekoExperimentId: product?.geekoExperimentId,
											geekoRequsestId: product?.geekoRequsestId,
											aliRequestId: product?.aliRequestId,
											aliExperimentId: product?.aliExperimentId,
											product_position: position + 1,
											price: product?.usdPrice?.amount,
											...sensors
										}
									} isGift={isGift} giftInfo={giftInfo} isWishList={isWishList} product_type={product_type} productId={selectedColor ? selectedColor.productId : product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} />

								</div>
							}
						</div>

					</div>
				}

			</figcaption>
		</a>
	</figure>

}))